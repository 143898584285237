<template>
  <div class="grid grid-col-1 container m-0 min-w-full">

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center mx-10 lg:mx-60">
        <h1 class="text-3xl text-left">Wer Bin Ich? </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
          ich heiße Maria Isabell Pieper und wurde im Jahr 2000 geboren. Ich komme aus NRW in Deutschland und habe schon, als ich klein war, gerne gemalt - vor allem aber in schwarz-weiß. Ich habe dieses Hobby jedoch damals nicht stark verfolgt. Erst als ich 2017 begonnen habe, zu studieren, habe ich angefangen, mehr Zeit in mein Hobby zu investieren. Tatsächlich studiere ich entgegen den Erwartungen nicht Kunst, sondern Chemie!<br><br>
          Ich habe damit gestartet, alle möglichen Bilder, die ich gemalt habe, auf Instagram zu zeigen. Zu der Zeit habe ich alles Mögliche gemalt. Von Disney Charakteren über Blumen bis hin zu Tieren.<br><br>
          Dabei habe ich angefangen, farbig zu arbeiten und auch viel kräftiger mit den Stiften aufzudrücken (was wichtig ist, damit es realistisch aussieht). Mit der Zeit habe ich dann angefangen, mich mehr und mehr auf das Zeichnen von Tieren zu konzentrieren und mehr Zeit in die einzelnen Zeichnungen zu investieren. 
        </div>
      </div>
    </div>

    <div class="row-span-1 h-64">

    </div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center mx-10 lg:mx-60">
        <h1 class="text-3xl text-left">Es geht los ... </h1>
        <hr class="border-1 border-black rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
          Nachdem ich ein Bild von unserem Familienhund auf Instagram veröffentlich hatte, hat mich jemand gefragt, ob ich seinen Hund zeichnen könnte.<br>
          <br>
          An diesem Punkt sah ich dann die Chance, etwas professioneller Aufträge zu zeichnen und mir neben dem Studium etwas dazu zu verdienen. Am wichtigsten ist mir dabei aber, den Spaß beim Zeichnen beizubehalten, weshalb ich im Moment hauptsächlich die süßen Vierbeiner, die uns so sehr am Herzen liegen, zu malen.<br>
        </div>
      </div>
    </div>

    <div class="row-span-1 h-64">

    </div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center mx-10 lg:mx-60">
        <h1 class="text-3xl text-left">artbyMIP </h1>
        <hr class="border-1 border-black rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
          Meine Initialen sind M.I.P. - daher artbyMIP. Unter diesem Namen habe ich angefangen, meine Bilder auf Instagram zu zeigen und unter diesem Namen möchte ich auch weiterhin für Interessierte meine Dienste anbieten.<br>
          <br>
          Ich liebe es, Haustiere zu zeichnen, weil ich aus eigener Erfahrung weiß, wie wichtig uns diese einzigartigen Familienmitglieder sind. Ich benutze dazu hochwertiges Papier und Buntstifte, da ich damit jedes einzelne Haar zeichnen kann und mit viel Detail, die Zeichnungen zum Leben erwecke. Mein Ziel ist es, die Tiere möglichst realistisch darzustellen, damit man auf jeden Fall erkennt, wer auf dem Bild ist.<br>
          <br>
          Jetzt habe ich diese Website, die Dir jede Menge Informationen darüber gibt, wie du auch ein Bild von mir kriegen kannst. Außerdem kannst du in der <a class="text-primary" href="/galery"> Galerie</a> einige meiner vorherigen Aufträge anschauen. <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
